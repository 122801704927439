import React from "react";
import "./slider.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer } from "@fortawesome/free-regular-svg-icons";

interface SliderProps {
    onAction?: () => void,
}

const Slider = (props: SliderProps) => {
    const [init, setInit] = React.useState<boolean>(false);

    const trackerRef = React.useRef<HTMLDivElement>(null);
    const laneRef = React.useRef<HTMLUListElement>(null);

    const size = 280;
    const numPictures = 44;

    React.useEffect(() => {
        const tracker = trackerRef.current;
        if(tracker != null) {
            const trackerContent = tracker.children[0] as HTMLDivElement;
            trackerContent.style.width = `${(size / 6) * numPictures}px`;
        }
    }, [trackerRef]);

    React.useEffect(() => {
        adjustSlider();
        const tracker = trackerRef.current;
        const lane = laneRef.current;
        if(tracker != null && lane != null) {
            tracker.onscroll = () => {
                setInit(true);
                if(props.onAction != undefined) {
                    props.onAction();
                }
                adjustSlider();
            }
        }
    }, [trackerRef, laneRef]);

    const adjustSlider = () => {
        const tracker = trackerRef.current;
        const lane = laneRef.current;

        if(tracker != null && lane != null) {
            const trackerContent = tracker.children[0] as HTMLDivElement;

            const current = tracker.scrollLeft / (trackerContent.clientWidth - tracker.clientWidth + 50);
            const elements = lane.children;

            for(let i = 0; i < elements.length; i++) {
                const item = elements[i] as HTMLLIElement;
                const imageContainer = item.children[0] as HTMLDivElement;
                const image = imageContainer.children[0] as HTMLImageElement;

                const normal = (i / numPictures) - current;

                const leftMid = (tracker.clientWidth - size) / 2;
                const left = Math.min(leftMid + Math.pow(normal, 2) * (normal > 0 ? 1 : -1) * (numPictures * 12) * (tracker.clientWidth - size), lane.clientWidth - size);
                const zIndex = Math.round((1 - Math.abs(normal)) * numPictures);
                
                imageContainer.style.left = `${left}px`;
                imageContainer.style.zIndex = `${zIndex}`;

                const k = (0.5 - Math.pow(normal, 2) / 0.5) * 2;
                const width = Math.max(k * size, 0);

                imageContainer.style.width = `${width}px`;
                imageContainer.className = i != Math.round(current * numPictures) ? "blur" : "";
                image.style.width = `${width}px`;
                image.style.display = k * k < 0.85 ? "none" : "block";
            }
        }
    }

    return (
        <div className="slider" onClick={() => {
            setInit(true);
            if(props.onAction != undefined) {
                props.onAction();
            }
        }}>
            <div className={`help-container ${init ? "hide" : ""}`}>
                <div>
                    <div style={{height: "20px"}}>
                        <div className="icon">
                            <FontAwesomeIcon icon={faHandPointer} />
                        </div>
                        <div className="text">Deslize para ver mais</div>
                    </div>
                </div>
            </div>
            <div className="barrier">
                <ul ref={laneRef} className="lane">
                    {Array.from(Array(numPictures).keys()).map((key) => (<li key={key} className="item"><div><img className={init ? "" : "not-init"} src={`./images/image${(key + 1).toString().padStart(5, "0")}.jpeg`} /></div></li>))}
                </ul>
            </div>
            <div ref={trackerRef} className="tracker">
                <div></div>
            </div>
        </div>
    );
}

export { Slider };