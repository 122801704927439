import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark, faForwardFast, faCheck } from "@fortawesome/free-solid-svg-icons";
import "./app.scss";
import { Slider } from "./slider";
import { CredentialResponse, GoogleLogin, useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";

function App() {
    const host = "https://api.passify.co";

    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const [init, setInit] = React.useState<boolean>(false);
    const [reverse, setReverse] = React.useState<boolean>(false);
    const [phoneEntered, setPhoneEntered] = React.useState<boolean>(false);
    const [registered, setRegistered] = React.useState<boolean>(false);
    const [confirmed, setConfirmed] = React.useState<boolean>(false);

    const [doScroll, setDoScroll] = React.useState<boolean>(true);
    const [timer, setTimer] = React.useState<NodeJS.Timer>();
    const [cursor0, setCursor0] = React.useState<number>(0);
    const [cursor1, setCursor1] = React.useState<number>(0);
    const [cursor2, setCursor2] = React.useState<number>(0);
    const [cursor3, setCursor3] = React.useState<number>(0);
    const [buttonVisible, setButtonVisible] = React.useState<boolean>(false);
    const [looksVisible, setLooksVisible] = React.useState<boolean>(false);
    const [mapVisible, setMapVisible] = React.useState<boolean>(false);

    const [email, setEmail] = React.useState<string>("");
    const [emailCursor, setEmailCursor] = React.useState<number | null>(null);
    const emailRef = React.useRef<HTMLInputElement>(null);

    const [phone, setPhone] = React.useState<string>("");
    const [phoneCursor, setPhoneCursor] = React.useState<number | null>(null);
    const phoneRef = React.useRef<HTMLInputElement>(null);

    const [googleCredentials, setGoogleCredentials] = React.useState<any>(null);

    const [resendCooldown, setResendCooldown] = React.useState<number>(0);

    const [verificationCode, setVerificationCode] = React.useState<string>("");
    const [verificationCodeCursor, setVerificationCodeCursor] = React.useState<number | null>(null);
    const verificationCodeRef = React.useRef<HTMLInputElement>(null);

    const typeDelay = 50;

    const text0 = "A RETRÔ TE CONVIDA PARA IMERGIR NO UNIVERSO CYBERPUNK DOS ANOS 90 E REVIVER ESSE SUBMUNDO TECNOLÓGICO";
    const text1 = "Data: 12/05 às 20h";
    const text2 = "*confirme sua presença até 10/05";
    const text3 = "Vem de carro 🚘, Uber ou 99?! Coloque o seguinte endereço: Rua Líbero Badaró, 561. Este endereço fica no cruzamento com a Avenida São João, a 50 metros do prédio onde está sediado o Lâmina.";

    const scrollToBottom = () => {
        if(doScroll) {
            const pageBottom = document.getElementById("page-bottom");
            pageBottom?.scrollIntoView({behavior: "smooth"});
        }
    }
    
    React.useEffect(() => {
        if(init) {
            scrollToBottom();
            setTimeout(() => {
                setTimer(setInterval(() => {
                    setCursor0(oldCursor => {
                        if(oldCursor < text0.length) {
                            return oldCursor + 1;
                        }
                        return oldCursor;
                    });
                }, typeDelay));
            }, 1000);

            return () => {
                if(timer != undefined) {
                    clearInterval(timer);
                }
            }
        }
    }, [init]);

    React.useEffect(() => {
        if(cursor0 == text0.length) {
            scrollToBottom();
            if(timer != undefined) {
                clearInterval(timer);
            }
            setTimeout(() => {
                setTimer(setInterval(() => {
                    setCursor1(oldCursor => {
                        if(oldCursor < text1.length) {
                            return oldCursor + 1;
                        }
                        return oldCursor;
                    });
                }, typeDelay));
            }, 1000);
            return () => {
                if(timer != undefined) {
                    clearInterval(timer);
                }
            }
        }

        return () => {}
    }, [cursor0]);

    React.useEffect(() => {
        if(cursor1 == text1.length) {
            scrollToBottom();
            setTimeout(() => {
                setButtonVisible(true);
            }, 1500);
        }
    }, [cursor1]);

    React.useEffect(() => {
        if(buttonVisible) {
            scrollToBottom();
            if(timer != undefined) {
                clearInterval(timer);
            }
            setTimeout(() => {
                setTimer(setInterval(() => {
                    setCursor2(oldCursor => {
                        if(oldCursor < text2.length) {
                            return oldCursor + 1;
                        }
                        return oldCursor;
                    });
                }, typeDelay));
            }, 2000);
            return () => {
                if(timer != undefined) {
                    clearInterval(timer);
                }
            }
        }

        return () => {}
    }, [buttonVisible]);

    React.useEffect(() => {
        if(cursor2 == text2.length) {
            scrollToBottom();
            setTimeout(() => {
                setLooksVisible(true);
            }, 1000);
        }
    }, [cursor2]);

    React.useEffect(() => {
        if(looksVisible) {
            if(doScroll) {
                const looksSection = document.getElementById("looks");
                looksSection?.scrollIntoView({behavior: "smooth"});
            }
            setTimeout(() => {
                setMapVisible(true);
            }, 2500);
        }
    }, [looksVisible]);

    React.useEffect(() => {
        if(mapVisible) {
            scrollToBottom();
            if(timer != undefined) {
                clearInterval(timer);
            }
            setTimeout(() => {
                setTimer(setInterval(() => {
                    setCursor3(oldCursor => {
                        if(oldCursor < text3.length) {
                            return oldCursor + 1;
                        }
                        return oldCursor;
                    });
                }, typeDelay));
            }, 1000);
            return () => {
                if(timer != undefined) {
                    clearInterval(timer);
                }
            }
        }

        return () => {}
    }, [mapVisible]);

    React.useEffect(() => {
        if(cursor3 == text3.length) {
            scrollToBottom();
            if(timer != undefined) {
                clearInterval(timer);
            }
        }
    }, [cursor3]);

    const maskEmail = (unmaskedEmail: string) => {
        if(unmaskedEmail == "") {
            return unmaskedEmail;
        }

        let match;

        match = unmaskedEmail.match(/[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
        if(match != null && match.length != 0) {
            return unmaskedEmail;
        }

        match = unmaskedEmail.match(/[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.?)+/);
        if(match != null && match.length != 0) {
            return unmaskedEmail;
        }

        match = unmaskedEmail.match(/[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@/);
        if(match != null && match.length != 0) {
            return unmaskedEmail;
        }

        match = unmaskedEmail.match(/[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*/);
        if(match != null && match.length != 0) {
            return unmaskedEmail;
        }

        return null;
    }

    const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
        const unmaskedEmail = e.currentTarget.value;
        const maskedEmail = maskEmail(unmaskedEmail);
        setEmail(oldEmail => maskedEmail != null ? maskedEmail : oldEmail);

        if(maskedEmail != null) {
            const selectionEnd = e.currentTarget.selectionEnd;
            const newSelectionEnd = (selectionEnd || 0) + (maskedEmail.length - e.currentTarget.value.length);
            setEmailCursor(newSelectionEnd);
        }

        e.preventDefault();
    }

    React.useEffect(() => {
        const input = emailRef.current;
        if(input != null) {
            input.setSelectionRange(emailCursor, emailCursor);
        }
    }, [emailRef, emailCursor, email]);

    const unmaskPhone = (maskedPhone: string) => {
        return maskedPhone.replaceAll(/[\(\)\- ]/g, "");
    }

    const maskPhone = (unmaskedPhone: string) => {
        if(unmaskedPhone == "") {
            return unmaskedPhone;
        }

        let match;

        // US
        match = unmaskedPhone.match(/^\+1([0-9]{3})([0-9]{3})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
        }

        match = unmaskedPhone.match(/^\+1([0-9]{3})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+1 (${match[1]}) ${match[2]}`;
        }

        match = unmaskedPhone.match(/^\+1([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+1 (${match[1]}`;
        }

        // Spain
        match = unmaskedPhone.match(/^\+34([0-9]{3})([0-9]{3})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+34 (${match[1]}) ${match[2]}-${match[3]}`;
        }

        match = unmaskedPhone.match(/^\+34([0-9]{3})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+34 (${match[1]}) ${match[2]}`;
        }

        match = unmaskedPhone.match(/^\+34([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+34 (${match[1]}`;
        }

        // Portugal
        match = unmaskedPhone.match(/^\+351([0-9]{2})([0-9]{3})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+351 (${match[1]}) ${match[2]}-${match[3]}`;
        }

        match = unmaskedPhone.match(/^\+351([0-9]{2})([0-9]{1,3})$/);
        if(match != null && match.length != 0) {
            return `+351 (${match[1]}) ${match[2]}`;
        }

        match = unmaskedPhone.match(/^\+351([0-9]{1,2})$/);
        if(match != null && match.length != 0) {
            return `+351 (${match[1]}`;
        }

        // Australia
        match = unmaskedPhone.match(/^\+61([0-9]{1})([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+61 ${match[1]} ${match[2]}-${match[3]}`;
        }

        match = unmaskedPhone.match(/^\+61([0-9]{1})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+61 ${match[1]} ${match[2]}`;
        }

        match = unmaskedPhone.match(/^\+61([0-9]{1})$/);
        if(match != null && match.length != 0) {
            return `+61 ${match[1]}`;
        }

        // Brasil
        match = unmaskedPhone.match(/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/);
        if(match != null && match.length != 0) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        match = unmaskedPhone.match(/^([0-9]{2})([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        match = unmaskedPhone.match(/^([0-9]{2})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `(${match[1]}) ${match[2]}`;
        }

        match = unmaskedPhone.match(/^([0-9]{1,2})$/);
        if(match != null && match.length != 0) {
            return `(${match[1]}`;
        }


        match = unmaskedPhone.match(/^\+55([0-9]{2})([0-9]{4,5})([0-9]{4})$/);
        if(match != null && match.length != 0) {
            return `+55 (${match[1]}) ${match[2]}-${match[3]}`;
        }

        match = unmaskedPhone.match(/^\+55([0-9]{2})([0-9]{4})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+55 (${match[1]}) ${match[2]}-${match[3]}`;
        }

        match = unmaskedPhone.match(/^\+55([0-9]{2})([0-9]{1,4})$/);
        if(match != null && match.length != 0) {
            return `+55 (${match[1]}) ${match[2]}`;
        }

        match = unmaskedPhone.match(/^\+55([0-9]{1,2})$/);
        if(match != null && match.length != 0) {
            return `+55 (${match[1]}`;
        }

        // General
        match = unmaskedPhone.match(/^(\+[0-9]{0,3})$/);
        if(match != null && match.length != 0) {
            return match[1];
        }

        return null;
    }

    const handlePhoneChange = (e: React.FormEvent<HTMLInputElement>) => {
        const unmaskedPhone = unmaskPhone(e.currentTarget.value);
        const maskedPhone = maskPhone(unmaskedPhone);
        setPhone(oldPhone => maskedPhone != null ? maskedPhone : oldPhone);

        if(maskedPhone != null) {
            const selectionEnd = e.currentTarget.selectionEnd;
            const newSelectionEnd = (selectionEnd || 0) + (maskedPhone.length - e.currentTarget.value.length);
            setPhoneCursor(newSelectionEnd);
        }

        e.preventDefault();
    }

    React.useEffect(() => {
        const input = phoneRef.current;
        if(input != null) {
            input.setSelectionRange(phoneCursor, phoneCursor);
        }
    }, [phoneRef, phoneCursor, phone]);

    const maskVerificationCode = (unmaskedVerificationCode: string) => {
        if(unmaskedVerificationCode == "") {
            return unmaskedVerificationCode;
        }

        let match;

        match = unmaskedVerificationCode.match(/^([0-9]{1,6})$/);
        if(match != null && match.length != 0) {
            return unmaskedVerificationCode;
        }

        return null;
    }

    const handleVerificationCodeChange = (e: React.FormEvent<HTMLInputElement>) => {
        const unmaskedVerificationCode = e.currentTarget.value;
        const maskedVerificationCode = maskVerificationCode(unmaskedVerificationCode);
        setVerificationCode(oldVerificationCode => maskedVerificationCode != null ? maskedVerificationCode : oldVerificationCode);

        if(maskedVerificationCode != null) {
            const selectionEnd = e.currentTarget.selectionEnd;
            const newSelectionEnd = (selectionEnd || 0) + (maskedVerificationCode.length - e.currentTarget.value.length);
            setVerificationCodeCursor(newSelectionEnd);
        }

        e.preventDefault();
    }

    React.useEffect(() => {
        const input = verificationCodeRef.current;
        if(input != null) {
            input.setSelectionRange(verificationCodeCursor, verificationCodeCursor);
        }
    }, [verificationCodeRef, verificationCodeCursor, verificationCode]);

    const fastForward = () => {
        setDoScroll(false);
        setCursor0(text0.length);
        setCursor1(text1.length);
        setButtonVisible(true);
        setCursor2(text2.length);
        setLooksVisible(true);
        setMapVisible(true);
        setCursor3(text3.length);
    }

    const toggleMenu = () => {
        setShowMenu(oldValue => !oldValue);
    };

    const goToInfo = () => {
        setShowMenu(false);
        const elem = document.querySelector("#info");
        if(elem != null) {
            window.scrollTo({top: elem!.getBoundingClientRect().top + window.scrollY - 64, behavior: "smooth"});
        }
    };

    const goToClaimTicket = () => {
        setShowMenu(false);
        const elem = document.querySelector(".morpheus-hands");
        if(elem != null) {
            window.scrollTo({top: elem!.getBoundingClientRect().top + window.scrollY - 64, behavior: "smooth"});
        }
    };

    const goToLooks = () => {
        setShowMenu(false);
        const elem = document.querySelector("#looks");
        if(elem != null) {
            window.scrollTo({top: elem!.getBoundingClientRect().top + window.scrollY - 64, behavior: "smooth"});
        }
    };

    const goToMap = () => {
        setShowMenu(false);
        const elem = document.querySelector("#map");
        if(elem != null) {
            window.scrollTo({top: elem!.getBoundingClientRect().top + window.scrollY - 64, behavior: "smooth"});
        }
    };

    const register = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        fetch(`${host}/retro/register`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "email": email,
                "phone": unmaskPhone(phone)[0] != "+" ? `+55${unmaskPhone(phone)}` : unmaskPhone(phone),
            }),
        }).then(response => {
            if (response.status == 200) {
                response.json().then(responseJSON => {
                    if (responseJSON["succeeded"] == true) {
                        setRegistered(true);
                        window.alert("Te mandamos um código de confirmação no seu WhatsApp!");
                    }
                });
            } else if(response.status == 400) {
                response.json().then(responseJSON => {
                    if (responseJSON["succeeded"] == false) {
                        switch(responseJSON["errors"][0]["code"] ) {
                            case 403:
                                window.alert("Este celular não foi convidado.");
                                break;
                            case 1001:
                                window.alert("Prazo de confirmação encerrado.");
                                break;
                            case 1002:
                                window.alert("E-mail ou celular incorreto(s).");
                                break;
                            case 1003:
                                window.alert("Sua presença já foi confirmada.");
                                break;
                        }
                        setPhoneEntered(false);
                    }
                });
            } else {
                window.alert("Erro desconhecido.");
            }
        });
        if(e != undefined) {
            e.preventDefault();
        }
    }

    const registerWithGoogle = (credentialResponse: CredentialResponse) => {
        setGoogleCredentials(credentialResponse);
        fetch(`${host}/retro/register-google`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "token": credentialResponse["credential"],
                "phone": unmaskPhone(phone)[0] != "+" ? `+55${unmaskPhone(phone)}` : unmaskPhone(phone),
            }),
        }).then(response => {
            if (response.status == 200) {
                response.json().then(responseJSON => {
                    if (responseJSON["succeeded"] == true) {
                        setRegistered(true);
                        window.alert("Te mandamos um código de confirmação no seu WhatsApp!");
                    }
                });
            } else if(response.status == 400) {
                response.json().then(responseJSON => {
                    if (responseJSON["succeeded"] == false) {
                        switch(responseJSON["errors"][0]["code"] ) {
                            case 403:
                                window.alert("Este celular não foi convidado.");
                                break;
                            case 1001:
                                window.alert("Prazo de confirmação encerrado.");
                                break;
                            case 1002:
                                window.alert("Celular incorreto(s).");
                                break;
                            case 1003:
                                window.alert("Sua presença já foi confirmada.");
                                break;
                        }
                        setPhoneEntered(false);
                    }
                });
            } else {
                window.alert("Erro desconhecido.");
            }
        });
    }

    const confirmAttendance = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        fetch(`${host}/retro/confirm-phone`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "phone": unmaskPhone(phone)[0] != "+" ? `+55${unmaskPhone(phone)}` : unmaskPhone(phone),
                "code": verificationCode
            }),
        }).then(response => {
            if (response.status == 200) {
                response.json().then(responseJSON => {
                    if (responseJSON["succeeded"] == true) {
                        if(responseJSON["value"]["emailConfirmed"]) {
                            window.location.href = `https://app.passify.co/retro/index.html?token=${responseJSON["value"]["whitelistToken"]}`;
                        }
                        setConfirmed(true);
                    } else {
                        window.alert("Código de verificação inválido");
                    }
                });
            } else if(response.status == 400) {
                window.alert("Código de verificação inválido");
            } else {
                window.alert("Erro desconhecido");
            }
        });
        e.preventDefault();
    }

    const resendCode = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if(googleCredentials != null) {
            registerWithGoogle(googleCredentials);
        } else {
            register();
        }
        setResendCooldown(15);
        e.preventDefault();
    }

    const notMyEmail = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        window.alert("Confirme novamente sua presença com o e-mail correto, por favor.");
        setEmail("");
        setVerificationCode("");
        setRegistered(false);
        setConfirmed(false);
        e.preventDefault();
    }

    React.useEffect(() => {
        setResendCooldown(15);
    }, [registered]);

    React.useEffect(() => {
        if(resendCooldown > 0) {
            const timeout = setTimeout(() => {
                setResendCooldown(oldCooldown => oldCooldown - 1);
            }, 1000);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, [resendCooldown]);

    return (
        <div id="app">
            <div id="app-header">
                <div className="passify-logo">
                    <a href="https://passify.co"><img src="/images/passify-dark.svg" /></a>
                </div>
                <video className={`video-mobile ${reverse ? "reverse" : ""}`} src="/videos/mobile.mp4" autoPlay={true} loop={true} playsInline={true} muted={true}></video>
                <video className={`video-desktop ${reverse ? "reverse" : ""}`} src="/videos/desktop.mp4" autoPlay={true} loop={true} playsInline={true} muted={true}></video>
                <div className="cta-container">
                <button className={init ? "hide" : ""} onClick={() => {
                    setReverse(true);
                    setTimeout(() => {
                        setInit(true);
                    }, 1500);
                }}>SAIR DA MATRIX</button>
                </div>
                <div id="menu" className={init && false ? "" : "hide"}>
                <button className="invisible" onClick={toggleMenu}><FontAwesomeIcon icon={faBars} /></button>
                </div>
            </div>
            <div id="app-body" className={init ? "" : "hide"}>
                <div>
                <div id="info">
                    <p>&gt; {text0.substring(0, cursor0)}{cursor0 < text0.length ? <span className="type-cursor">█</span> : <></>}</p>
                    <h3>{cursor0 >= text0.length && ">"} {text1.substring(0, cursor1)}{cursor0 >= text0.length && cursor1 < text1.length ? <span className="type-cursor">█</span> : <></>}</h3>
                    {buttonVisible && <div id="claim-ticket">
                        <form>
                            {!phoneEntered ? <>
                                <div className="form-field-container">
                                    <label htmlFor="form-phone">Celular</label>
                                    <input ref={phoneRef} id="form-phone" type="text" placeholder="(XX) XXXX-XXXXX" value={phone} onChange={handlePhoneChange} onFocus={() => { setDoScroll(false); }} />
                                </div>
                                <div className="form-button-container">
                                    <button className="primary" onClick={(e) => {
                                        setPhoneEntered(true);
                                        e.preventDefault();
                                    }}>Confirmar presença</button>
                                </div>
                            </> : !registered ? <>
                                <div className="form-field-container">
                                    <label htmlFor="form-email">E-mail</label>
                                    <input id="form-email" type="email" placeholder="fulano@gmail.com" value={email} onChange={handleEmailChange} onFocus={() => { setDoScroll(false); }} />
                                </div>
                                <div className="form-button-container">
                                    <button className="primary" onClick={register}>Confirmar presença</button>
                                </div>
                                <div className="form-button-container" style={{textAlign: "center"}}>
                                    ou
                                </div>
                                <div className="form-button-container">
                                    <GoogleLogin onSuccess={registerWithGoogle} onError={() => window.alert("Falha ao autenticar com Google")} itp_support={true} />
                                </div>
                            </> : !confirmed ? <>
                                <div className="form-field-container">
                                    <label htmlFor="form-verification-code">Código de verificação</label>
                                    <input ref={verificationCodeRef} id="form-verification-code" type="verification-code" placeholder="000000" value={verificationCode} onChange={handleVerificationCodeChange} />
                                </div>
                                <div className="form-button-container">
                                    <button className="primary" onClick={confirmAttendance}>Confirmar presença</button>
                                </div>
                                <p className="form-resend" style={{textAlign: "center"}}>
                                    {resendCooldown > 0 ? `Reenviar novo código em ${resendCooldown}s...` : <a href="#" onClick={resendCode}>Reenviar código</a>}
                                </p>
                            </> : <div className="form-confirmed">
                                <div className="check">
                                    <FontAwesomeIcon icon={faCheck} />
                                </div>
                                <p style={{textAlign: "center"}}>
                                    Sua presença foi confirmada!<br />Mandamos um link para acessar seu ingresso no seu e-mail<br />{email}<br /><a href="#" onClick={notMyEmail}>Este não é meu e-mail</a>
                                </p>
                            </div>}
                        </form>
                    </div>}
                    <p>{buttonVisible && ">"} {text2.substring(0, cursor2)}{buttonVisible && cursor2 < text2.length ? <span className="type-cursor">█</span> : <></>}</p>
                    {looksVisible && <div id="looks">
                        <h2>O CYBER PUNK</h2>
                        <Slider onAction={() => { setDoScroll(false); }} />
                    </div>}
                    {mapVisible && <h2>O LOCAL</h2>}
                    {mapVisible && <p style={{textAlign: "center"}}>Estúdio Lâmina<br />Av. São João, 108 - 41 - Centro Histórico de São Paulo</p>}
                    {mapVisible && <div id="map" onClick={() => { window.open("https://goo.gl/maps/6GJXvgLSt8WwFVa76", "_blank"); }}>
                        {false && <div className="pin-container">
                            <div id="pin1"><img src="/images/pin.svg" /></div>
                        </div>}
                        <div className="pin-container">
                            <div id="pin2"><img src="/images/pin.svg" /></div>
                        </div>
                    </div>}
                    <p style={mapVisible ? {minHeight: "117px"} : {}}>{mapVisible && ">"} {text3.substring(0, cursor3)}{mapVisible && cursor3 < text3.length ? <span className="type-cursor">█</span> : <></>}</p>
                    {cursor3 >= text3.length && <div className="passify-logo">
                        <a href="https://passify.co"><img src="/images/passify-dark.svg" /></a>
                    </div>}
                    {cursor3 < text3.length && <div id="fast-forward-button-container"><button onClick={fastForward}><FontAwesomeIcon icon={faForwardFast} /></button></div>}
                </div>
                <div id="page-bottom"></div>
                </div>
            </div>
            <div id="app-menu" className={showMenu ? "active" : ""}>
                <div className="button-container"><button className="invisible" onClick={toggleMenu}><FontAwesomeIcon icon={faXmark} /></button></div>
                <ul>
                    <li onClick={goToInfo}>Informações</li>
                    <li onClick={goToClaimTicket}>Confirmar Presença</li>
                    <li onClick={goToLooks}>Moodboard</li>
                    <li onClick={goToMap}>Onde vai ser?</li>
                </ul>
                <hr />
                <div className="passify-logo"><a href="https://passify.co/"><img src="/images/passify-dark.svg" width={70} alt="Passify" /></a></div>
            </div>
        </div>
    );
}

export { App };
